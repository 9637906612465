import React from "react"
import Image from "gatsby-image/withIEPolyfill"

import { AspectRatio, FlexGrid, Wrapper } from "components"

const GallerySection = ({ data, id }) => {
  return (
    <Wrapper id={id}>
      <FlexGrid
        content={data.images.map(({ image }) => ({
          width: ["100%", "50%"],
          children: (
            <AspectRatio ratio="4:3" bg="wash">
              <Image
                fluid={image && image.fluid}
                alt={image && image.alt}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                objectFit="cover"
                objectPosition="center"
              />
            </AspectRatio>
          ),
        }))}
      />
    </Wrapper>
  )
}

export default GallerySection
