import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import { PopUp, SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import ServicesSection from "./_servicesSection"
import GallerySection from "./_gallerySection"
import AboutSection from "./_aboutSection"
import FullImageSection from "./_fullImageSection"
import InfoSection from "./_infoSection"

function IndexPage() {
  const staticData = useStaticQuery(graphql`
    {
      prismicHome {
        data {
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_image {
            url
            alt
          }
          hero_title {
            text
          }
          hero_subtitle {
            text
          }
          hero_button_text
          hero_image {
            fluid(maxWidth: 1900) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          services_intro {
            text
          }
          services_closing {
            text
          }
          customer_types {
            text
          }
          customers {
            type_of_customer
            icon {
              fluid(maxWidth: 64) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
          }
          services_button_text
          featured_services {
            service {
              uid
              document {
                ... on PrismicService {
                  uid
                  data {
                    name {
                      text
                    }
                    color
                    bin_image {
                      fluid(maxWidth: 64) {
                        ...GatsbyPrismicImageFluid
                      }
                      alt
                    }
                  }
                }
              }
            }
          }
          about_gallery {
            image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
          }
          about_description {
            html
          }
          about_button_text
          featured_awards {
            title {
              text
            }
            logo {
              fluid(maxWidth: 256) {
                ...GatsbyPrismicImageFluid
              }
              alt
            }
            award
            link {
              url
            }
          }
          sustainability_image {
            fluid(maxWidth: 1900) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          sustainability_description {
            html
          }
          highlighted_stat
          highlighted_stat_label
          sustainability_button_text
          # Pop Up
          popup_visibility
          popup_image {
            fluid(maxWidth: 640) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          popup_heading {
            text
          }
          popup_content {
            html
          }
          popup_optionalbuttonlabel
          popup_optionalbuttonlink {
            link_type
            url
            target
          }
        }
      }
    }
  `)

  const previewData =
    typeof window !== "undefined" && window.__PRISMIC_PREVIEW_DATA__
  const finalData = mergePrismicPreviewData({ staticData, previewData })

  if (finalData && finalData.prismicHome) {
    return (
      <>
        <SEO
          title={
            finalData.prismicHome.data.seo_title &&
            finalData.prismicHome.data.seo_title.text
          }
          description={
            finalData.prismicHome.data.seo_description &&
            finalData.prismicHome.data.seo_description.text
          }
          image={
            finalData.prismicHome.data.seo_image &&
            finalData.prismicHome.data.seo_image.url
          }
          imageAlt={
            finalData.prismicHome.data.seo_image &&
            finalData.prismicHome.data.seo_image.alt
          }
        />

        {/* ============ INTRO ============ */}
        <IntroSection
          id="home-intro"
          data={{
            image: finalData.prismicHome.data.hero_image,
            title:
              finalData.prismicHome.data.hero_title &&
              finalData.prismicHome.data.hero_title.text,
            subtitle:
              finalData.prismicHome.data.hero_subtitle &&
              finalData.prismicHome.data.hero_subtitle.text,
            buttonLabel: finalData.prismicHome.data.hero_button_text,
          }}
        />

        {/* ============ SERVICES ============ */}
        {finalData.prismicHome.data.featured_services && (
          <ServicesSection
            id="home-services"
            data={{
              firstLine:
                finalData.prismicHome.data.services_intro &&
                finalData.prismicHome.data.services_intro.text,

              services: finalData.prismicHome.data.featured_services.map(
                ({ service }) => ({
                  image: service.document && service.document.data.bin_image,
                  label:
                    service.document &&
                    service.document.data.name &&
                    service.document.data.name.text,
                  color:
                    service.document &&
                    service.document.data.name &&
                    service.document.data.color,
                  to: `/services/${service.document && service.document.uid}/`,
                })
              ),
              secondLine:
                finalData.prismicHome.data.services_closing &&
                finalData.prismicHome.data.services_closing.text,
              customerTypeHeading:
                finalData.prismicHome.data.customer_types &&
                finalData.prismicHome.data.customer_types.text,
              customers: finalData.prismicHome.data.customers,
              buttonLabel: finalData.prismicHome.data.services_button_text,
            }}
          />
        )}

        {/* ============ GALLERY ============ */}
        {finalData.prismicHome.data.about_gallery && (
          <GallerySection
            id="home-gallery"
            data={{
              images: finalData.prismicHome.data.about_gallery.map(
                ({ image }) => ({
                  image: image,
                })
              ),
            }}
          />
        )}

        {/* ============ ABOUT ============ */}
        <AboutSection
          id="home-about"
          data={{
            bodyContent:
              finalData.prismicHome.data.about_description &&
              finalData.prismicHome.data.about_description.html,
            buttonLabel: finalData.prismicHome.data.about_button_text,
            buttonTo: "/about/",
            cards:
              finalData.prismicHome.data.featured_awards &&
              finalData.prismicHome.data.featured_awards.map(
                ({ title, logo, award, link }) => ({
                  image: logo,
                  description: award,
                  link: link,
                  title: title && title.text,
                })
              ),
          }}
        />

        {/* ============ FULL IMAGE ============ */}
        {finalData.prismicHome.data.sustainability_image && (
          <FullImageSection
            id="home-fullImage"
            data={{ image: finalData.prismicHome.data.sustainability_image }}
          />
        )}

        {/* ============ INFO ============ */}
        <InfoSection
          id="home-info"
          data={{
            bodyContent:
              finalData.prismicHome.data.sustainability_description &&
              finalData.prismicHome.data.sustainability_description.html,
            statValue: finalData.prismicHome.data.highlighted_stat,
            statDescription: finalData.prismicHome.data.highlighted_stat_label,
            buttonLabel: finalData.prismicHome.data.sustainability_button_text,
            buttonTo: "/sustainability/",
          }}
        />

        <PopUp
          visibility={finalData.prismicHome.data.popup_visibility}
          image={finalData.prismicHome.data.popup_image}
          heading={finalData.prismicHome.data.popup_heading}
          content={finalData.prismicHome.data.popup_content}
          buttonLabel={finalData.prismicHome.data.popup_optionalbuttonlabel}
          buttonLink={finalData.prismicHome.data.popup_optionalbuttonlink}
        />
      </>
    )
  } else {
    return null
  }
}

export default IndexPage
