import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { Link as ScrollLink } from "react-scroll"
import { mdiArrowDown } from "@mdi/js"
import { linearGradient, rgba } from "polished"

import { Box, Button, ColorMode, Heading, Text, Wrapper } from "components"

const IntroSection = ({ data, id }) => {
  const CONTENT_START_ID = "index-contentStart"

  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.subtitle || data.buttonLabel) && (
        <>
          <ColorMode mode="dark">
            <Box
              id={id}
              as="section"
              position="fixed"
              top={0}
              left={0}
              zIndex={-1}
              width="100%"
              css={{
                //IE11 Fallback
                height: "100vh",
                height: "calc(var(--vh, 1vh) * 100)", // We use CSS variables for this to keep vertical height units static on iOS
              }}
            >
              <Wrapper mt={0} mb={0} pt="calc(32px + 48px + 128px)" bg={null}>
                <Image
                  fluid={data.image && data.image.fluid}
                  alt={data.image && data.image.alt}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  objectFit="cover"
                  objectPosition="center"
                />
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  css={{
                    ...linearGradient({
                      colorStops: [
                        `${rgba("#151515", 0.5)} 0%`,
                        `${rgba("#151515", 0.125)} 100%`,
                      ],
                      toDirection: "to bottom",
                      fallback: "transparent",
                    }),
                  }}
                />
                <Box
                  position="relative"
                  width={["100%", "50%"]}
                  pr={[0, "layout.6"]}
                >
                  {/* Heading */}
                  {data.title && <Heading size={900}>{data.title}</Heading>}

                  {/* Subheading */}
                  {data.subtitle && (
                    <Text size={600} mt="layout.2">
                      {data.subtitle}
                    </Text>
                  )}

                  {/* Scroll down button */}
                  {/* We wrap the Button with the ScrollLink component because it doesn't behave nicely when using "as" prop */}
                  {data.buttonLabel && (
                    <ScrollLink smooth={true} to={CONTENT_START_ID}>
                      <Button
                        as="div"
                        iconAfter={mdiArrowDown}
                        intent="primary"
                        appearance="primary"
                        height={[40, 48]}
                        mt={["layout.3", "layout.4"]}
                      >
                        {data.buttonLabel}
                      </Button>
                    </ScrollLink>
                  )}
                </Box>
              </Wrapper>
            </Box>
          </ColorMode>

          {/* Content start. Creates spacing for intro scroll effect and indicates the start of content for ScrollTo button */}
          <Box
            data-component-id="contentStart"
            id={CONTENT_START_ID}
            as="section"
            pt={["layout.6", "layout.8"]}
            bg="background"
            css={{
              //fallback value for IE11
              marginTop: "100vh",
              marginTop: "calc(var(--vh, 1vh) * 100)",
            }}
          />
        </>
      )}
    </>
  )
}

export default IntroSection
