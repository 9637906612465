import React from "react"
import { Link } from "gatsby"
import { mdiArrowRight } from "@mdi/js"

import {
  Box,
  Button,
  CommunityCard,
  FlexGrid,
  RichText,
  Wrapper,
} from "components"

const AboutSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.bodyContent ||
        data.buttonLabel ||
        data.buttonTo ||
        data.cards) && (
        <Wrapper id={id}>
          {/* Description */}
          <RichText
            content={{
              html: data.bodyContent,
            }}
            size={600}
            width={["100%", "50%"]}
          />

          {/* Link */}
          {data.buttonLabel && data.buttonTo && (
            <Button
              as={Link}
              to={data.buttonTo}
              iconAfter={mdiArrowRight}
              intent="primary"
              appearance="primary"
              height={[40, 48]}
              mt={["layout.4", null, "layout.6"]}
            >
              {data.buttonLabel}
            </Button>
          )}

          {/* Information card */}
          {data.cards && (
            <Box mt="layout.6" mx={["-16px", "-32px"]}>
              <FlexGrid
                content={data.cards.map(
                  (card, index) =>
                    // We only show cards if they have a description set
                    card.description && {
                      width: ["100%", "33.333%"],
                      children: (
                        <Box key={"homeHighlightCard" + index}>
                          <CommunityCard
                            title={card.title}
                            image={card.image}
                            description={card.description}
                            link={card.link}
                          />
                        </Box>
                      ),
                    }
                )}
                gutterY={[2, 4]}
                gutterX={[2, 2, 16]}
              />
            </Box>
          )}
        </Wrapper>
      )}
    </>
  )
}

export default AboutSection
