import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"
import { mdiArrowRight } from "@mdi/js"
import Image from "gatsby-image/withIEPolyfill"

import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FlexGrid,
  Heading,
  ServicePill,
  Text,
  Wrapper,
} from "components"

const Item = ({ label, icon }) => (
  <Box>
    <Flex alignItems="center">
      <Box width={32}>
        <AspectRatio ratio="1:1">
          {icon && icon.fluid && (
            <Image
              fluid={icon.fluid}
              alt={icon.alt}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              objectFit="cover"
              objectPosition="center"
            />
          )}
        </AspectRatio>
      </Box>
      <Box flex={1} ml="layout.2">
        <Text>{label}</Text>
      </Box>
    </Flex>
  </Box>
)

const ServicesSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.firstLine || data.secondLine || data.services) && (
        <Wrapper id={id}>
          <Heading as="div" size={900} fontWeight="normal">
            {/* First line */}
            {data.firstLine && (
              <Box
                as="p"
                m={0}
                pb={data.services && ["layout.3", null, null, "layout.4"]}
              >
                {data.firstLine}
              </Box>
            )}

            {/* Services split */}
            {data.services && (
              <Flex
                flexWrap="wrap"
                m={["-2px", "-4px", null, "-8px"]}
                css={css({
                  "> *": {
                    p: ["2px", "4px", null, "8px"],
                  },
                })}
              >
                {data.services.map((service, index) => (
                  <ServicePill
                    image={service.image}
                    title={service.label}
                    color={service.color}
                    to={service.to}
                    key={index}
                  />
                ))}
              </Flex>
            )}

            {/* Second line */}
            {data.secondLine && (
              <Box as="p" m={0} pt={data.services && "layout.2"}>
                {data.secondLine}
              </Box>
            )}

            {/* Who we service */}
            <Box mt={["layout.4", null, "layout.6"]}>
              <Heading size={500}>Who we service</Heading>
              <Box mt="layout.2">
                <FlexGrid
                  gutterY={[2]}
                  content={data.customers.map((customer, index) => ({
                    width: ["100%", null, "50%"],
                    children: (
                      <Item
                        icon={customer.icon}
                        label={customer.type_of_customer}
                        key={"customer" + index}
                      />
                    ),
                  }))}
                />
              </Box>
            </Box>

            {data.buttonLabel && (
              <Button
                as={Link}
                to={"/services/"}
                iconAfter={mdiArrowRight}
                intent="primary"
                appearance="primary"
                height={[40, 48]}
                mt={["layout.4", null, "layout.6"]}
              >
                {data.buttonLabel}
              </Button>
            )}
          </Heading>
        </Wrapper>
      )}
    </>
  )
}

export default ServicesSection
