import React from "react"
import { Link } from "gatsby"
import { mdiArrowRight } from "@mdi/js"

import { Button, FluidText, RichText, Stat, Wrapper } from "components"

const InfoSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.bodyContent ||
        data.statValue ||
        data.statDescription ||
        data.buttonLabel ||
        data.buttonTo) && (
        <Wrapper id={id}>
          {/* Description */}
          {data.bodyContent && (
            <RichText
              content={{
                html: data.bodyContent,
              }}
              size={600}
              width={["100%", "50%"]}
            />
          )}

          {/* Stat */}
          {data.statValue && data.statDescription && (
            <FluidText mt={["layout.4", null, "layout.6"]}>
              <Stat value={data.statValue} />
              {" " + data.statDescription}
            </FluidText>
          )}

          {/* LinkButton */}
          {data.buttonLabel && data.buttonTo && (
            <Button
              as={Link}
              to={data.buttonTo}
              iconAfter={mdiArrowRight}
              intent="primary"
              appearance="primary"
              height={[40, 48]}
              mt={["layout.4", null, "layout.6"]}
            >
              {data.buttonLabel}
            </Button>
          )}
        </Wrapper>
      )}
    </>
  )
}

export default InfoSection
